import createAction from '../../middleware/actions';

export default {
    getAll: createAction(async ({ extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/user/settings");
            return { settings: result.settings }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    save: createAction(async (settings, { extra }) => {
        try {
            let result = await extra.api.post("/api/v1/private/user/settings", settings);
            return { settings: result.settings }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    saveLogo: createAction(async (logo, { extra }) => {
        try {
            let result = await extra.api.post("/api/v1/private/user/settings", { logo });
            return { settings: result.settings }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    saveEntreprise: createAction(async (entreprise, { extra }) => {
        try {
            let result = await extra.api.post("/api/v1/private/user/settings", { entreprise });
            return { settings: result.settings }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    savePaiement: createAction(async (paiement, { extra }) => {
        try {
            let result = await extra.api.post("/api/v1/private/user/settings", { paiement });
            return { settings: result.settings }
        } catch (err) {
            throw { message: err.message };
        }
    })
}