import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withNavigation } from '../../providers/navigation';
import { withStoreProvider } from '../../providers/StoreProvider';
import { withSnackBar } from '../../providers/snackBar';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';


import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Loader from '../../components/Loader';
import actions from '../../actions';
import routeMdw from '../..//middleware/route';

function LoginPage(props) {

    const intl = props.intl;

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [displayLoader, setDisplayLoader] = useState(false);

    return <Box>

        <Loader display={displayLoader} />

        <Box sx={{
            position: 'absolute',
            left: '50%',
            top: '40%',
            width: "80%",
            transform: 'translate(-50%, -40%)',
            textAlign: 'center'
        }}>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main', textAlign: 'center' }}>
                    <LockOutlinedIcon />
                </Avatar>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={1} sm={2} lg={4}  sx={{ textAlign: 'center' }} />
                <Grid item xs={10} sm={8} lg={4}  sx={{ textAlign: 'center' }}>
                    <Typography component="h1" variant="h5">
                        {intl.formatMessage({ id: 'login.singin' })}
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={2} lg={4} sx={{ textAlign: 'center' }} />
            </Grid>
 
            <Grid container spacing={2} sx={{paddingTop : '15px'}}>
                <Grid item xs={1} sm={2} lg={4} sx={{ textAlign: 'center' }} />
                <Grid item xs={10} sm={8} lg={4} sx={{ textAlign: 'center' }}>
                    <TextField type="text" label={intl.formatMessage({ id: 'login.username' })} variant="outlined" sx={{ width: "100%", textAlign: "center" }} onChange={(event) => {
                        setUsername(event.target.value);
                    }} />
                </Grid>
                <Grid item xs={1} sm={2} lg={4} sx={{ textAlign: 'center' }} />
            </Grid>
            <Grid container spacing={2} sx={{ paddingTop: '20px' }}>
                <Grid item xs={1} sm={2} lg={4} sx={{ textAlign: 'center' }} />
                <Grid item xs={10} sm={8} lg={4} sx={{ textAlign: 'center' }}>
                    <TextField type="password" label={intl.formatMessage({ id: 'login.password' })}  variant="outlined" sx={{ width: "100%", textAlign: "center" }} onChange={(event) => {
                        setPassword(event.target.value);
                    }} />
                </Grid>
                <Grid item xs={1} sm={2} lg={4} sx={{ textAlign: 'center' }} />
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={1} sm={2} lg={4} sx={{ textAlign: 'center' }} />
                <Grid item xs={10} sm={8} lg={4} sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        onClick={async () => {
                            try {
                                setDisplayLoader(true);
                                let result = await props.dispatch(actions.user.login(username, password));
                                if (result.user) {
                                    if (result.user.active == true){
                                        try {
                                            await props.dispatch(actions.settings.getAll());
                                        } catch (err) {

                                        }
                                        props.navigation.push(routeMdw.urlHome());
                                    } else {
                                        props.snackbar.info(intl.formatMessage({ id: "user.activation.waiting" }));
                                    }
                                }
                            } catch (err) {
                                props.snackbar.error(intl.formatMessage({ id: err.message }));
                            } finally {
                                setDisplayLoader(false);
                            }
                        }}
                    >{intl.formatMessage({ id: 'button.validate' })}</Button>
                </Grid>
                <Grid item xs={1} sm={2} lg={4} sx={{ textAlign: 'center' }} />
            </Grid>
            <Grid container sx={{ paddingTop: '10px' }}>
                <Grid item xs={1} sm={2} lg={4} />
                <Grid item xs={10} sm={8} lg={4} >
                    <Grid container sx={{ padding: '5px' }}>
                        <Grid item xs={6} sx={{ textAlign: 'left' }}>
                            <Link component="button" 
                                onClick={() => {
                                    props.navigation.push(routeMdw.urlForgotPassword());
                                }} variant="body2">
                                {intl.formatMessage({ id: 'login.password.forgot' })}
                            </Link>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <Link component="button"  onClick={() => {
                                props.navigation.push(routeMdw.urlSignup());
                            }} variant="body2">
                                {intl.formatMessage({ id: 'login.account.signup' })}
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} sm={2} lg={4}>

                </Grid>
            </Grid>


        </Box>

    </Box>
}

export default withStoreProvider(withSnackBar(withNavigation(injectIntl(LoginPage))));
