import createAction from '../../middleware/actions';

export default {
    getByManufacturerId: createAction(async (id , { extra }) => {
        try {
            let result = await extra.api.get("/api/modelseries", { query: { manuid: id } });
            return { modelseries: result.modelseries }
        } catch (err) {
            throw { message: err.message };
        }
    })
}