import createAction from '../../middleware/actions';

export default {
    create: createAction(async (client = {}, { extra }) => {
        try {
            let result = await extra.api.post("/api/v1/private/client", client);
            return { client: result.client }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    update: createAction(async (client = {}, { extra }) => {
        try {
            let result = await extra.api.post("/api/v1/private/client", client);
            return { client: result.client }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    delete: createAction(async (id, { extra }) => {
        try {
            let result = await extra.api.del("/api/v1/private/client", { id });
        } catch (err) {
            throw { message: err.message };
        }
    }),
}