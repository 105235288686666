import React, { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { withNavigation } from '../../providers/navigation';
import { withStoreProvider } from '../../providers/StoreProvider';
import { withSnackBar } from '../../providers/snackBar';
import InputMask from 'react-input-mask';
import { v4 } from 'uuid';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Switch from '@mui/material/Switch';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';

import actions from '../../actions';
import Loader from '../../components/Loader';
import routeMdw from '../../middleware/route';

import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import PasswordAsk from '../../components/PasswordAsk';

const ValidationSchema = Yup.object().shape({
    nom: Yup.string().required(),
    adresse1: Yup.string().required(),
    adresse2: Yup.string(),
    code_postal: Yup.string().required(),
    ville: Yup.string().required(),
    email: Yup.string().email(),
    siret: Yup.string().required(),
    telephone: Yup.string().required(),
    rcs: Yup.string().required(),
});

const ValidationSchemaPaiement = Yup.object().shape({
    nom: Yup.string().required(),
    iban: Yup.string().required(),
    order: Yup.string().required(),
});

function SettingsPage(props) {
    const intl = props.intl;
    const globalState = props.globalState;

    const inputRef = useRef(null);
    const inputJsonRef = useRef(null);

    const [displayChangeUUIDModal, setDisplayChangeUUIDModal] = useState(false);
    const [displayLoader, setDisplayLoader] = useState(false);
    const [displayAmBrandsSelector, setDisplayAmBrandsSelector] = useState(false);
    const [displayDatabaseInstaller, setDisplayDatabaseInstaller] = useState(false);
    const [uuidImg, setUuidImg] = useState("");

    const [displayPasswordAskForDebug, setDisplayPasswordAskForDebug] = useState(false);
    const [displayPasswordAskForGodMode , setDisplayPasswordAskForGodMode] = useState(false);

    const handleFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        const reader = new FileReader();

        reader.onload = async function (e) {
            const text = e.target.result;
            await props.dispatch(actions.settings.saveLogo(text));
            await props.dispatch(actions.settings.getAll());
            props.navigation.push(routeMdw.urlHome());
            setDisplayLoader(false);
        };

        reader.onerror = (err) => {
            console.error(err);
        }

        reader.readAsDataURL(fileObj);
    }

    const handleJSONChange = event => {
        const fileObj = event.target.files && event.target.files[0];

        if (!fileObj) {
            return;
        }
        const reader = new FileReader();

        reader.onload = async function (e) {
            const text = e.target.result;
            await props.dispatch(actions.user.restoreUserDatabase(JSON.parse(text)));
            await props.dispatch(actions.settings.getAll());
            props.snackbar.success(intl.formatMessage({ id: 'user.database.restored' }));
            props.navigation.push(routeMdw.urlHome());
            setDisplayLoader(false);
        };

        reader.onerror = (err) => {
            console.error(err);
        }

        reader.readAsText(fileObj, "UTF-8");
    }

    let initialValues = {
        nom: globalState?.settings?.entreprise?.nom || "",
        adresse1: globalState?.settings?.entreprise?.adresse1 || "",
        adresse2: globalState?.settings?.entreprise?.adresse2 || "",
        code_postal: globalState?.settings?.entreprise?.code_postal || "",
        ville: globalState?.settings?.entreprise?.ville || "",
        telephone: globalState?.settings?.entreprise?.telephone || "",
        email: globalState?.settings?.entreprise?.email || "",
        siret: globalState?.settings?.entreprise?.siret || "",
        rcs: globalState?.settings?.entreprise?.rcs || "",
    }

    let initialValuesPaiement = {
        nom: globalState?.settings?.paiement?.nom || '',
        iban: globalState?.settings?.paiement?.iban || '',
        order: globalState?.settings?.paiement?.order || '',
    }

    return <Box>

        <Loader display={displayLoader} />

        <List>
            <Formik
                initialValues={initialValues}
                validationSchema={ValidationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    setDisplayLoader(true);
                    await props.dispatch(actions.settings.saveEntreprise(values));
                    props.snackbar.success(intl.formatMessage({ id: 'settings.societe.saved' }));
                    setDisplayLoader(false);
                }}
            >
                {({ values, errors, touched, handleSubmit, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                        <ListItem disablePadding>
                            <Typography variant="h5" gutterBottom component="div">{intl.formatMessage({ id: 'settings.societe.title' })}</Typography>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary="Nom" />
                            <TextField error={(errors.nom && touched.nom) ? true : false} variant="standard" sx={{ textAlign: "center" }} name="nom" value={values.nom} onChange={handleChange} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Adresse" />
                            <TextField error={(errors.adresse1 && touched.adresse1) ? true : false} variant="standard" sx={{ textAlign: "center" }} name="adresse1" value={values.adresse1} onChange={handleChange} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Adresse (complément)" />
                            <TextField error={(errors.adresse2 && touched.adresse2) ? true : false} variant="standard" sx={{ textAlign: "center" }} name="adresse2" value={values.adresse2} onChange={handleChange} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Code Postal" />
                            <InputMask error={(errors.code_postal && touched.code_postal) ? true : false} value={values.code_postal} mask="99999" maskChar=" " name="code_postal" alwaysShowMask={false} onChange={handleChange}>
                                {(inputProps) => <TextField {...inputProps} variant="standard" sx={{ textAlign: "center" }} disableunderline />}
                            </InputMask>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Ville" />
                            <TextField error={(errors.ville && touched.ville) ? true : false} variant="standard" sx={{ textAlign: "center" }} name="ville" value={values.ville} onChange={handleChange} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Telephone" />
                            <InputMask error={(errors.telephone && touched.telephone) ? true : false} value={values.telephone} mask="99.99.99.99.99" maskChar=" " name="telephone" alwaysShowMask={false} onChange={handleChange}>
                                {(inputProps) => <TextField {...inputProps} variant="standard" sx={{ textAlign: "center" }} disableunderline />}
                            </InputMask>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Mail" />
                            <TextField error={(errors.email && touched.email) ? true : false} variant="standard" sx={{ textAlign: "center" }} name="email" value={values.email} onChange={handleChange} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Siret" />
                            <TextField error={(errors.siret && touched.siret) ? true : false} variant="standard" sx={{ textAlign: "center" }} name="siret" value={values.siret} onChange={handleChange} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Ville ( RCS )" />
                            <TextField error={(errors.rcs && touched.rcs) ? true : false} variant="standard" sx={{ textAlign: "center" }} name="rcs" value={values.rcs} onChange={handleChange} />
                        </ListItem>
                        <ListItem>
                            <Grid container spacing={2}>
                                <Grid item xs={2} />
                                <Grid item xs={8} sx={{ textAlign: 'center' }}>
                                    <Stack direction="row" spacing={2} sx={{ display: 'block' }}>
                                        <Button variant="contained" type="submit">
                                            {intl.formatMessage({ id: 'settings.database.societe.save' })}
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={2} />
                            </Grid>
                        </ListItem>
                    </Form>
                )}
            </Formik>
            <br />
            <Formik
                initialValues={initialValuesPaiement}
                validationSchema={ValidationSchemaPaiement}
                onSubmit={async (values, { setSubmitting }) => {
                    setDisplayLoader(true);
                    await props.dispatch(actions.settings.savePaiement(values));
                    props.snackbar.success(intl.formatMessage({ id: 'settings.paiement.saved' }));
                    setDisplayLoader(false);
                }}
            >
                {({ values, errors, touched, handleSubmit, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                        <ListItem disablePadding>
                            <Typography variant="h5" gutterBottom component="div">{intl.formatMessage({ id: 'settings.paiement.title' })}</Typography>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary="Nom associé au compte bancaire" />
                            <TextField error={(errors.nom && touched.nom) ? true : false} variant="standard" sx={{ textAlign: "center" }} name="nom" value={values.nom} onChange={handleChange} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="IBAN" />
                            <TextField error={(errors.iban && touched.iban) ? true : false} variant="standard" sx={{ textAlign: "center" }} name="iban" value={values.iban} onChange={handleChange} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Chéque a l'ordre de" />
                            <TextField error={(errors.order && touched.order) ? true : false} variant="standard" sx={{ textAlign: "center" }} name="order" value={values.order} onChange={handleChange} />
                        </ListItem>
                        <ListItem>
                            <Grid container spacing={2}>
                                <Grid item xs={2} />
                                <Grid item xs={8} sx={{ textAlign: 'center' }}>
                                    <Stack direction="row" spacing={2} sx={{ display: 'block' }}>
                                        <Button variant="contained" type="submit">
                                            {intl.formatMessage({ id: 'settings.database.societe.save' })}
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={2} />
                            </Grid>
                        </ListItem>
                    </Form>
                )}
            </Formik>
            <br />
            <ListItem disablePadding>
                <Typography variant="h5" gutterBottom component="div">{intl.formatMessage({ id: 'settings.logo.title' })}</Typography>
            </ListItem>
            <Divider />
            <ListItem>
                <Grid container spacing={2}>
                    <Grid item xs={2} />
                    <Grid item xs={8} sx={{ textAlign: 'center' }}>
                        {globalState?.settings?.logo && <img src={/*'data:image/png;base64,' +*/ globalState?.settings?.logo} width={305 / 2} height={140 / 2} />}
                        {!globalState?.settings?.logo && <Typography variant="h8" gutterBottom component="div">{intl.formatMessage({ id: 'settings.logo.no' })}</Typography>}
                    </Grid>
                    <Grid item xs={2} />
                </Grid>
            </ListItem>
            <ListItem>
                <Grid container spacing={2}>
                    <Grid item xs={2} />
                    <Grid item xs={8} sx={{ textAlign: 'center' }}>
                        <Stack direction="row" spacing={2} sx={{ display: 'block' }}>
                            <input
                                style={{ display: 'none' }}
                                ref={inputRef}
                                type="file"
                                onChange={handleFileChange}
                            />
                            <Button variant="contained" onClick={async () => {
                                try {
                                    setDisplayLoader(true);
                                    // 👇️ open file input box on click of another element
                                    inputRef.current.click();
                                } catch (err) {
                                    props.snackbar.error(err.message);
                                }
                            }}>
                                {intl.formatMessage({ id: 'settings.logo.select' })}
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={2} >
                        <DeleteForeverIcon sx={{ marginTop: "5px", cursor: "pointer" }} onClick={() => {
                            props.dispatch(actions.settings.saveLogo("".toString('base64')));
                            setDisplayLoader(false);
                        }} />
                    </Grid>
                </Grid>
            </ListItem>
            <br />
            <ListItem disablePadding>
                <Typography variant="h5" gutterBottom component="div">{intl.formatMessage({ id: 'settings.database.title' })}</Typography>
            </ListItem>
            <Divider />
            <ListItem>
                <Grid container spacing={2}>
                    <Grid item xs={2} />
                    <Grid item xs={8} sx={{ textAlign: 'center' }}>
                        <Stack direction="row" spacing={2} sx={{ display: 'block' }}>
                            <Button variant="contained" onClick={async () => {

                                try {
                                    setDisplayLoader(true);
                                    const database = (await props.dispatch(actions.user.dumpUserDatabase()))?.database;
                                    const json = JSON.stringify(database, null, 2);
                                    const blob = new Blob([json], { type: "application/json" });
                                    const href = URL.createObjectURL(blob);
                                    // create "a" HTLM element with href to file
                                    const link = document.createElement("a");
                                    link.href = href;
                                    link.download = "AEGarage_database_" + new Date().getFullDate() + new Date().getFullMonth() + new Date().getFullYear() + ".json";
                                    document.body.appendChild(link);
                                    link.click();
                                    // clean up "a" element & remove ObjectURL
                                    document.body.removeChild(link);
                                    URL.revokeObjectURL(href);


                                    /*const filename = (await props.dispatch(actions.electron.getFilenameForSave('.json')))?.getFilenameForSave;
                                    if (filename.canceled == false) {
                                        let dump = await props.dispatch(actions.database.dumpFromDB());
                                        await props.dispatch(actions.electron.writeFile(filename.filePath, JSON.stringify(dump.dump)));

                                        props.snackbar.success(intl.formatMessage({ id: 'settings.database.export.success' }));
                                    }*/
                                } catch (err) {
                                    props.snackbar.error(err.message);
                                } finally {
                                    setDisplayLoader(false);
                                }
                            }}>
                                {intl.formatMessage({ id: 'settings.database.export' })}
                            </Button>
                            <input
                                style={{ display: 'none' }}
                                ref={inputJsonRef}
                                type="file"
                                onChange={handleJSONChange}
                            />
                            <Button variant="contained" onClick={async () => {
                                try {
                                    setDisplayLoader(true);
                                   
                                    // 👇️ open file input box on click of another element
                                    inputJsonRef.current.click();

                                } catch (err) {
                                    props.snackbar.error(err.message);
                                } 
                            }}>
                                {intl.formatMessage({ id: 'settings.database.import' })}
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={2} />
                </Grid>
            </ListItem>
            {/*<br />
            <ListItem disablePadding>
                <Typography variant="h5" gutterBottom component="div">{intl.formatMessage({ id: 'settings.adminMode' })}</Typography>
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText primary="Mode Admin" />
                <Switch
                    checked = {globalState?.settings?.tempSettings?.godMode}
                    onChange={async (event) => {
                        event.target.checked ? setDisplayPasswordAskForGodMode(event.target.checked) : props.dispatch(actions.set.tempSettings({ godMode: event.target.checked }));  
                    }} />
            </ListItem>
            <ListItem>
                <ListItemText primary="Ouvrir la console" />
                <Switch 
                checked = {globalState.debugConsole}
                onChange={async (event) => {
                    event.target.checked ? setDisplayPasswordAskForDebug(event.target.checked) : props.dispatch(actions.debug.toggleConsole(event.target.checked));
                }} />
            </ListItem>*/}
        </List>

        {displayPasswordAskForGodMode && <PasswordAsk
            title={intl.formatMessage({ id: 'settings.admin.password.title' })}
            label={intl.formatMessage({ id: 'settings.admin.password.label' })}
            password={process.env.REACT_APP_GODMODE_PASSWORD}
            display={displayPasswordAskForGodMode}
            onClose={()=> {
                setDisplayPasswordAskForGodMode(false);
            }}
            onValidate={async (val)=>{
                await props.dispatch(actions.set.tempSettings({ godMode: val }))
                setDisplayPasswordAskForGodMode(false);
            }}
        />} 
        {displayPasswordAskForDebug && <PasswordAsk
            title={intl.formatMessage({ id: 'settings.admin.password.title' })}
            label={intl.formatMessage({ id: 'settings.admin.password.label' })}
            password={process.env.REACT_APP_DEBUG_PASSWORD}
            display={displayPasswordAskForDebug}
            onClose={()=> {
                setDisplayPasswordAskForDebug(false);
            }}
            onValidate={async (val)=>{
                await props.dispatch(actions.debug.toggleConsole(val));
                setDisplayPasswordAskForDebug(false);
            }}
        />}

    </Box>;
}

export default withStoreProvider(withNavigation(withSnackBar(injectIntl(SettingsPage))));