import createAction from '../../middleware/actions';

export default {
    create: createAction(async (devi = {}, { extra }) => {
        try {
            let result = await extra.api.post("/api/v1/private/devi", devi);
            return { devi: result.devi }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getLastNumber: createAction(async ({ extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/devi", { query: { lastNumber: 0 }});
            return { devisNumber: result.lastNumber }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getById: createAction(async (id = "", { extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/devi", { query: { id: id } });
            return { devi: result.devi }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    printPDF: createAction(async (devis = {}, { extra, getState }) => {
        try {
            let result = await extra.pdf.devis(devis, true , { extra, getState });
            return {}
        }
        catch (err) {
            throw { message: err.message };
        }
    }),
    savePDF: createAction(async (devis = {}, { extra, getState }) => {
        try {
            let result = await extra.pdf.devis(devis, false, { extra, getState });
            return {}
        }
        catch (err) {
            throw { message: err.message };
        }
    }),
}