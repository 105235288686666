import createAction from '../../middleware/actions';

export default {
    getAll: createAction(async ({ extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/vehicules");
            return { vehicules: result.vehicules }
        } catch (err) {
            throw { message: err.message };
        }
    })
}