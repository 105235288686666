
import createAction from '../../middleware/actions';

export default {
    getAll: createAction(async ({ extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/catalogue");
            return {
                brands: result.brands
            }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getByTecdocId: createAction(async (tecdocId, { extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/catalogue", { query: { tecdocId: tecdocId } });

            for (let categorie of result?.categories) {
                categorie.hasArticles = false;
                for (let brand of result?.amBrands) {
                    if (brand?.articles.find(el => el.assemblyGroupNodeId == categorie.assemblyGroupNodeId)) {
                        categorie.hasArticles = true;
                    }
                }
            }

            function _processArticles() {
                for (let categorie of result?.categories) {
                    if (categorie.hasArticles == true) {
                        if (categorie.parentNodeId) {
                            for (let _cat of result?.categories) {
                                if (_cat.assemblyGroupNodeId == categorie.parentNodeId) {
                                    if (_cat.hasArticles == false) {
                                        _cat.hasArticles = true;
                                        _processArticles();
                                    }
                                }
                            }
                        }
                    }
                }
            }

            function _processChilds() {
                for (let categorie of result?.categories) {
                    if (categorie.hasChilds == undefined) {
                        categorie.hasChilds = false;

                        for (let _cat of result?.categories) {
                            if (_cat.assemblyGroupNodeId == categorie.parentNodeId) {
                                if (_cat.hasChilds == false) {
                                    _cat.hasChilds = true;
                                    _processChilds();
                                }
                            }
                        }
                    }
                }
            }

            _processArticles();
            _processChilds();

            return {
                brands: result?.amBrands,
                categories: result?.categories
            }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getArticlesState: createAction(async (brandId, assemblyGroupNodeId, tecdocId, { extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/catalogue/articleState", { query: { brandId: brandId, assemblyGroupNodeId: assemblyGroupNodeId, tecdocId: tecdocId } });

            return {
                articlesState: result?.articlesState,
            }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getDirectArticle: createAction(async (articleId, { extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/catalogue/directArticle", { query: { articleId: articleId } });
            return {
                directArticle: result?.directArticles[0],
            }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getArticleDetails: createAction(async (articleId, { extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/catalogue/articleDetails", { query: { articleId: articleId } });
            return {
                articleDetails: result?.articleDetails[0],
            }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getArticleAttributes: createAction(async (articleId, { extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/catalogue/articleAttributes", { query: { articleId: articleId } });
            return {
                articleAttributes: result?.articleAttributes,
            }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getArticleAttributes: createAction(async (articleId, carId , { extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/catalogue/articleAttributes", { query: { articleId: articleId, carId: carId } });
            return {
                articleAttributes: result?.articleAttributes,
            }
        } catch (err) {
            throw { message: err.message };
        }
    })
}
