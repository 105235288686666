import createAction from '../../middleware/actions';

export default {
    getFromPlate: createAction(async (plate, { extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/vehicule", { query: { plate: plate } });
            return { vehicule: result.vehicule }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    setSelected: createAction(async (vehicule = {}, { extra }) => {
        try {
            /*
            let vehicleDetails = {};

            if (vehicleDetails?.carId == undefined) {
                vehicule.tecdoc = undefined;
            } else {
                vehicule.tecdoc = {
                    manuId: vehicleDetails.manuId,
                    modelId: vehicleDetails.modId,
                    carId: vehicleDetails.carId
                };
            }*/
            
            return {
                selectedVehicule: { ...vehicule }
            };
        } catch (err) {
            throw { message: err.message };
        }
    }),
    delete: createAction(async (id, { extra }) => {
        try {
            let result = await extra.api.del("/api/v1/private/vehicule", { id });
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getByTecdocId : createAction(async ( tecdocid , { extra }) => {
        try {
            let result = await extra.api.get("/api/vehicule", { query: { tecdocid: tecdocid } });
            return { vehicule: result.vehicule }
        } catch (err) {
            throw { message: err.message };
        }
    })
}