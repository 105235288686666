
import createAction from '../../middleware/actions';

export default {
    getByModelId: createAction(async (modelid, { extra }) => {
        try {
            let result = await extra.api.get("/api/motorisations", { query: { modelid: modelid } });
            return { motorisation: result.motorisation }
        } catch (err) {
            throw { message: err.message };
        }
    })
}

