import createAction from '../../middleware/actions';

export default {
    create: createAction(async (produit = {}, { extra }) => {
        try {
            let result = await extra.api.post("/api/v1/private/produit", produit);
            return { product: result.produit }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    update: createAction(async (produit = {}, { extra }) => {
        try {
            let result = await extra.api.post("/api/v1/private/produit", produit);
            return { product: result.produit }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    delete: createAction(async (id, { extra }) => {
        try {
            let result = await extra.api.del("/api/v1/private/produit", { id });
        } catch (err) {
            throw { message: err.message };
        }
    }),
}