import createAction from '../../middleware/actions';

export default {
    getAll: createAction(async ({ extra }) => {
        try {
            let result = await extra.api.get("/api/manufacturers");
            return { manufacturers: result.manufacturers }
        } catch (err) {
            throw { message: err.message };
        }
    })
}