
import createAction from '../../middleware/actions';

export default {
    getAll: createAction(async ({ extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/services");
            return { services: result.services }
        } catch (err) {
            throw { message: err.message };
        }
    }),
}
