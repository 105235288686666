import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withStoreProvider } from '../../providers/StoreProvider';
import { withNavigation } from '../../providers/navigation';
import { withSnackBar } from '../../providers/snackBar';

import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import actions from '../../actions';
import Loader from '../../components/Loader';

function CatalogSelectCategorie(props) {
    const intl = props.intl;

    const selectedVehicule = props.globalState.selectedVehicule;

    const [displayLoader, setDisplayLoader] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        setDisplayLoader(true);
        async function fetchData() {
            try {
                let result = await props.dispatch(actions.catalogue.getByTecdocId(selectedVehicule.tecdocId));
                setCategories(result?.categories);
            } catch (err) {
                props.snackbar.error(intl.formatMessage({ id: 'fetch.error' }));
            } finally {
                setDisplayLoader(false);
            }
        }
        fetchData();
    }, []);

    function generateTree(cat, assemblyGroupNodeId = undefined) {
        return cat.map((_cat, _idx) => {
            if (_cat.parentNodeId == assemblyGroupNodeId) {
                if (_cat.hasArticles) {
                    return <TreeItem itemId={"grid-" + _idx} label={_cat.assemblyGroupName} onClick={() => {
                        props.onSelect && props.onSelect(_cat);
                    }}>
                        {generateTree(cat, _cat.assemblyGroupNodeId)}
                    </TreeItem>
                }
            }
        })
    }
    return <span>
        <Loader display={displayLoader} />
        <SimpleTreeView>
            {generateTree(categories)}
        </SimpleTreeView>
    </span>;
}

export default withNavigation(withStoreProvider(withSnackBar(injectIntl(CatalogSelectCategorie))));