import createAction from '../../middleware/actions';

export default {
    getAll: createAction(async ({ extra }) => {
        try {
            let cp = await extra.api.get("/api/v1/public/codepostaux");
            return { __code_postaux__: cp.code_postaux };
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getVillesFromCp: createAction(async (codePostale, { extra, getState }) => {
        try {
            let cp = getState().__code_postaux__;
            if (codePostale.trim().length >= 2) {
                let value = cp.map((el) => {
                    if (el.code_postal.toString().startsWith(codePostale.trim())) {
                        return el;
                    }
                }).filter((el) => el != undefined);

                return { code_postaux: value };
            }
            return { code_postaux: [] }
        } catch (err) {
            throw { message: err.message };
        }
    })
}