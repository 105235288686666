import createAction from '../../middleware/actions';

export default {
    login: createAction(async (username, password, { extra, getState }) => {
        try {
            let result = await extra.api.post("/api/v1/public/user/login", { username, password });
            return { user: result.user };
        } catch (err) {
            throw { message: err.message };
        }
    }),
    signup: createAction(async (user, { extra, getState }) => {
        try {
            let result = await extra.api.post("/api/v1/public/user/create", { ...user });
            return { user: result.user };
        } catch (err) {
            throw { message: err.message };
        }
    }),
    logout: createAction(async ({ extra, getState }) => {
        try {
            let result = await extra.api.post("/api/v1/private/user/logout");
            return { user: undefined };
        } catch (err) {
            throw { message: err.message };
        }
    }),
    forgotPassword: createAction(async (data, { extra }) => {
        try {
            let result = await extra.api.post("/api/v1/public/user/forgotpassword", { ...data });
            return { user: undefined };
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getSession: createAction(async ({ extra }) => {
        try {
            let result = await extra.api.get("/api/v1/public/user/session");
            return { user: result.user , message : result.message};
        } catch (err) {
            throw { message: err.message };
        }
    }),
    dumpUserDatabase: createAction(async ({ extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/user/database");
            return { database: result.database };
        } catch (err) {
            throw { message: err.message };
        }
    }),
    restoreUserDatabase: createAction(async (database, { extra }) => {
        try {
            let result = await extra.api.post("/api/v1/private/user/database", { database: database });
            return { database: result.database };
        } catch (err) {
            throw { message: err.message };
        }
    }),
}